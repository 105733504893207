import type {
    ApiError,
    ClassifiedAdSearchData,
    DashboardPinnedNewsAnnouncement,
    MainNewsAnnouncementData,
    MainNewsAnnouncementPage,
    MainNewsAnnouncementsData, MainNewsPostPage, MainNewsPostsData, MainSingleClassifiedAdData, NewsAnnouncement,
    NewsHomeData,
    NewsInstantSearchData, NewsPost, NewsPostCategory, NewsSearchData,
    NewsTicker, SavedClassifiedAd
} from "~/types";
import {useNuxtApp} from "#app";

export const useMainNewsDataService = () => {
    const nuxtApp = useNuxtApp();
    const baseUrl = useRuntimeConfig().public.baseApiUrl;
    const route = useRoute();

    const fetchMainNewsAnnouncement = async () => {
        const {data, error} = await useAsyncData(`classified-ad-${route.params.slug}`,
            () => nuxtApp.$getNewsAnnouncementBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value as MainNewsAnnouncementData;
        }
    }

    const fetchMainNewsPost = async () => {
        const {data, error} = await useAsyncData(`classified-ad-${route.params.slug}`,
            () => nuxtApp.$getNewsPostBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsPost;
        }
    }

    const fetchMainNewsTickers = async () => {
        const {data, error} = await useAsyncData(`news-tickers`,
            () => nuxtApp.$getNewsTickers()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsTicker[];
        }
    }

    const fetchNewsPostCategory = async () => {
        if (route.query.category) {
            const {data, error} = await useAsyncData(`news-post-category-${route.query.category}`,
                () => nuxtApp.$getNewsPostCategoryBySlug(route.query.category as string)
            );
            if (error.value != null) {
                setError(error.value.data as ApiError);
                return null;
            } else {
                return data.value as NewsPostCategory;
            }
        }
        return null;
    }

    const fetchMainNewsAnnouncementsData = async () => {
        const {data, error} = await useAsyncData(`main-news-announcements`,
            () => useNuxtApp().$getNewsAnnouncementsDataFromUrl(`${baseUrl}/news-announcements${route.params.query != null && route.params.query != '' ? (route.params.query as string) : ''}`));

        if (error.value !== null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed(() => {
                return {
                    news_announcement_page: {
                        featured_news_announcements: data.value.featured_news_announcements,
                        non_featured_news_announcement: data.value.news_announcements.data,
                        page_number: data.value.news_announcements.meta.current_page,
                    } as MainNewsAnnouncementPage,
                    pagination_meta_data: data.value.news_announcements.meta,
                    pagination_links: data.value.news_announcements.links,
                } as MainNewsAnnouncementsData
            });

        }
    };

    const fetchMainNewsAnnouncementsDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getNewsAnnouncementsDataFromUrl(url);
            return {
                news_announcement_page: {
                    featured_news_announcements: response.featured_news_announcements,
                    non_featured_news_announcement: response.news_announcements.data,
                    page_number: response.news_announcements.meta.current_page,
                } as MainNewsAnnouncementPage,
                pagination_meta_data: response.classified_ads.meta,
                pagination_links: response.classified_ads.links,
            } as MainNewsAnnouncementsData
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    const fetchMainNewsPostsData = async () => {
        const {data, error} = await useAsyncData(`main-news-posts`,
            () => useNuxtApp().$getNewsPosts(route.params.category as string??null)
        );
        if (error.value !== null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed(() => {
                return {
                    news_post_page: {
                        news_posts: data.value.data,
                        page_number: data.value.meta.current_page,
                    } as MainNewsPostPage,
                    pagination_meta_data: data.value.meta,
                    pagination_links: data.value.links,
                } as MainNewsPostsData
            });

        }
    };

    const fetchMainNewsPostsDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getNewsAnnouncementsDataFromUrl(url);
            return {
                news_post_page: {
                    news_posts: response.data,
                    page_number: response.meta.current_page,
                } as MainNewsPostPage,
                pagination_meta_data: response.meta,
                pagination_links: response.links,
            } as MainNewsPostsData

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    const fetchMainNewsData = async () => {
        const {data, error} = await useAsyncData(`main-news`,
            () => useNuxtApp().$getNewsHomeData()
        );

        if (error.value !== null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsHomeData;
        }
    };

    const requestToCreateNewNewsAnnouncementReport = async (data: object) => {
        try {
            const response = await nuxtApp.$createNewsAnnouncementReport(data);
            return response.data.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToPinANewsAnnouncement = async () => {
        try {
            const response = await nuxtApp.$pinNewsAnnouncementBySlug(route.params.slug as string);
            return response.data as DashboardPinnedNewsAnnouncement;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchInstantSearchResults = async (query: string) => {
        try {
            const response = await nuxtApp.$getInstantSearchResultsForNewsByQuery(query)
            return response as NewsInstantSearchData;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchNewsAnnouncementsBySearchCriteria = async(query:string)=>{

        try{
            const response = await nuxtApp.$searchNewsAnnouncementsByCriteria(query);
            return response as NewsSearchData;
        }
        catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return {
        fetchMainNewsTickers,
        fetchInstantSearchResults,

        fetchMainNewsData,
        fetchMainNewsAnnouncementsData,
        fetchMainNewsAnnouncementsDataFromUrl,
        fetchMainNewsAnnouncement,
        fetchMainNewsPost,
        requestToCreateNewNewsAnnouncementReport,
        requestToPinANewsAnnouncement,
        fetchMainNewsPostsData,
        fetchMainNewsPostsDataFromUrl,
        fetchNewsPostCategory,

        fetchNewsAnnouncementsBySearchCriteria,
    }
}
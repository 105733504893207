import type {ClassifiedInstantSearchData, NewsInstantSearchData} from "~/types";
import {useMainNewsDataService} from "~/composables/useMainNewsDataService";


export const useMainNewsDataManager = () => {
    const mainNewsDataService = useMainNewsDataService();
    const newsStore = useNews();

    const getMainNewsAnnouncement = async () => {
        const post = await mainNewsDataService.fetchMainNewsAnnouncement();
        return post;
    }

    const getMainNewsPost = async () => {
        return await mainNewsDataService.fetchMainNewsPost();
    }

    const getMainNewsTickers = async () => {
        const storedNewsTickers = computed(() => {
            return newsStore.news_tickers;
        })

        if (storedNewsTickers.value == null || storedNewsTickers.value.length == 0 || !newsStore.isNewsTickersValid()) {
            const newsTickers = await mainNewsDataService.fetchMainNewsTickers();
            if (newsTickers)
                newsStore.setNewsTickers(newsTickers);
        }

        return storedNewsTickers;
    }

    const getMainNewsAnnouncements = async () => {
        const results = await mainNewsDataService.fetchMainNewsAnnouncementsData();
        return results?.value;
    }

    const getMainNewsAnnouncementsFromUrl = async (url:string) => {
        return await mainNewsDataService.fetchMainNewsAnnouncementsDataFromUrl(url);

    }

    const getMainNewsPosts = async () => {
        const results = await mainNewsDataService.fetchMainNewsPostsData();
        return results?.value;
    }

    const getNewsPostCategory = async ()=>{
        return await mainNewsDataService.fetchNewsPostCategory();
    }

    const getMainNewsPostsFromUrl = async (url:string) => {
        return await mainNewsDataService.fetchMainNewsPostsDataFromUrl(url);
    }

    const getMainNews = async ()=>{
        return await mainNewsDataService.fetchMainNewsData();
    }

    const reportNewsAnnouncement = async (data:object) => {
        const createdNewsAnnouncementReport = await mainNewsDataService.requestToCreateNewNewsAnnouncementReport(data);
        if (createdNewsAnnouncementReport) {
            useAlertSetter().setSuccessAlert("Announcement Reported Successfully");
            return createdNewsAnnouncementReport;
        }
        return null;
    }

    const pinNewsAnnouncement = async () => {
        const pinnedNewsAnnouncement = await mainNewsDataService.requestToPinANewsAnnouncement();
        if (pinnedNewsAnnouncement) {
            useAlertSetter().setSuccessAlert("Announcement Pinned Successfully");
            return pinnedNewsAnnouncement;
        }
        return null;
    }

    const getNewsInstantSearchResults = async(query:string)=>{
        return await mainNewsDataService.fetchInstantSearchResults(query) as NewsInstantSearchData;
    }

    const searchNewsAnnouncementsByCriteria = async (query:string)=>{
        const searchResults =  await mainNewsDataService.fetchNewsAnnouncementsBySearchCriteria(query);
        if(searchResults){
            return searchResults
        } else{
            return null;
        }
    }

    return {
        getMainNewsTickers,
        getMainNewsAnnouncements,
        getMainNewsAnnouncementsFromUrl,
        getNewsInstantSearchResults,
        getMainNews,
        getMainNewsAnnouncement,
        getMainNewsPost,
        reportNewsAnnouncement,
        pinNewsAnnouncement,
        getMainNewsPosts,
        getMainNewsPostsFromUrl,
        getNewsPostCategory,

        searchNewsAnnouncementsByCriteria,

    }

}